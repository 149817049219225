<template>
    <div>
        <el-dialog
                v-model="seeVisible"
                width="75%"
                :fullscreen="isFullscreen"
                :before-close="handleClose"
                @close="close"
                @open="open"
            >
                <div style="cursor: pointer;position: absolute;top:0.06rem;right: 0.5rem;" @click="fullScreen">
                    <el-icon>
					    <FullScreen />
				    </el-icon>
                    <!-- {{isFullscreen ? '关闭全屏' : '全屏'}} -->
                </div>
                <div class="dialogBox" v-if="track != '教师'">
                    <el-tabs v-model="activeName" type="border-card" class="demo-tabs" @tab-click="handleClick">
                        <el-tab-pane label="信息通信工程实践" name="1" style="position: relative;">
                            <el-table  v-loading="loading" border :scrollbar-always-on="true" :data="tableData"
                            style="width: 100%;border-radius: 0.05rem;">
                            <el-table-column type="index" sortable label="序号" width="60" />
                            <el-table-column prop="school" sortable label="学校全称" width="120" />
                            <el-table-column prop="province" sortable label="省份" />
                            <el-table-column prop="group" sortable label="组别" width="180" />
                            <!-- <el-table-column :key="index" v-for="(item,index) in this.tableData.players" :prop="item.name" sortable :label="item.lable" width="180" /> -->

                            <el-table-column prop="name1" sortable label="参赛选手1" width="180" />
                            <el-table-column prop="id_num1" sortable label="身份证号" width="180" />
                            <el-table-column prop="phone1" sortable label="手机号" width="180" />
                            <el-table-column prop="mail1" sortable label="邮箱" width="180" />
                            <el-table-column prop="sex1" sortable label="性别" width="180" />
                            <el-table-column prop="grade1" sortable label="学级" width="180" />
                            <el-table-column prop="profession1" sortable label="专业名称" width="180" />
                            <el-table-column prop="name2" sortable label="参赛选手2" width="180" />
                            <el-table-column prop="id_num2" sortable label="身份证号" width="180" />
                            <el-table-column prop="phone2" sortable label="手机号" width="180" />
                            <el-table-column prop="mail2" sortable label="邮箱" width="180" />
                            <el-table-column prop="sex2" sortable label="性别" width="180" />
                            <el-table-column prop="grade2" sortable label="学级" width="180" />
                            <el-table-column prop="profession2" sortable label="专业名称" width="180" />
                            <el-table-column prop="name3" sortable label="指导教师1" width="180" />
                            <el-table-column prop="phone3" sortable label="手机号" width="180" />
                            <el-table-column prop="mail3" sortable label="邮箱" width="180" />
                            <el-table-column prop="name4" sortable label="指导教师2" width="180" />
                            <el-table-column prop="phone4" sortable label="手机号" width="180" />
                            <el-table-column prop="mail4" sortable label="邮箱" width="180" />
                            <el-table-column prop="name5" sortable label="带队教师" width="180" />
                            <el-table-column prop="phone5" sortable label="手机号" width="180" />
                            </el-table>
                        </el-tab-pane>
                        <el-tab-pane label="产教融合5G+创新应用设计赛" name="2" style="position: relative;">
                            <el-table v-loading="innovateLoading" border :scrollbar-always-on="true" :data="tableData2" style="width: 100%;border-radius: 0.05rem;">
                            <el-table-column type="index" sortable label="序号" width="60" />
                            <el-table-column prop="school" sortable label="学校全称" width="120" />
                            <el-table-column prop="province" sortable label="学校所在省份"  width="140"/>
                            <el-table-column prop="name" sortable label="队名" width="180" />
                            <el-table-column prop="role" sortable label="角色" width="180" />
                            <el-table-column prop="name1" sortable label="姓名" width="180" />
                            <el-table-column prop="id_num" sortable label="身份证号" width="180" />
                            <el-table-column prop="phone" sortable label="手机号" width="180" />
                            <el-table-column prop="mail" sortable label="邮箱" width="180" />
                            <el-table-column prop="sex" sortable label="性别" width="180" />
                            <el-table-column prop="grade" sortable label="学级" width="180" />
                            <el-table-column prop="profession" sortable label="专业名称" width="180" />

                            
                    </el-table>
                        </el-tab-pane>

                    </el-tabs>
                    <!-- <p  style="margin-bottom: 0.1rem;">信息通信工程实践</p> -->
                </div>
                <div class="dialogBox" v-if="track == '教师'">
                    <el-tabs v-model="activeName" type="border-card" class="demo-tabs" @tab-click="handleClick">
                        <el-tab-pane label="信息通信工程实践" name="1" style="position: relative;">
                            <el-table  v-loading="loading" border :scrollbar-always-on="true" :data="tableData"
                            style="width: 100%;border-radius: 0.05rem;">
                            <el-table-column type="index" sortable label="序号" width="60" />
                        <el-table-column prop="school" sortable label="学校全称" width="120" />
                        <el-table-column prop="province" sortable label="省份" />
                        <el-table-column prop="group" sortable label="组别" width="180" />

                        <el-table-column prop="name1" sortable label="参赛选手" width="180" />
                        <el-table-column prop="id_num1" sortable label="身份证号" width="180" />
                        <el-table-column prop="phone1" sortable label="手机号" width="180" />
                        <el-table-column prop="mail1" sortable label="邮箱" width="180" />
                        <el-table-column prop="name5" sortable label="带队教师" width="180" />
                        <el-table-column prop="phone5" sortable label="手机号" width="180" />
                            </el-table>
                        </el-tab-pane>
                        
                    </el-tabs>
                    <!-- <p  style="margin-bottom: 0.1rem;">信息通信工程实践</p> -->
                </div>
                <!-- <div class="dialogBox">
                    <p style="margin-bottom: 0.1rem;">5G+创新应用实践赛</p>
                    <el-table v-loading="innovateLoading" border :data="tableData2" style="width: 100%;border-radius: 0.05rem;">
                            <el-table-column type="index" sortable label="序号" width="60" />
                            <el-table-column prop="school" sortable label="学校全称" width="120" />
                            <el-table-column prop="province" sortable label="学校所在省份"  width="140"/>
                            <el-table-column prop="name" sortable label="队名" width="180" />
                            <el-table-column prop="role" sortable label="角色" width="180" />
                            <el-table-column prop="name" sortable label="姓名" width="180" />
                            <el-table-column prop="id_num" sortable label="身份证号" width="180" />
                            <el-table-column prop="phone" sortable label="手机号" width="180" />
                            <el-table-column prop="mail" sortable label="邮箱" width="180" />
                            <el-table-column prop="sex" sortable label="性别" width="180" />
                            <el-table-column prop="grade" sortable label="学级" width="180" />
                            <el-table-column prop="profession" sortable label="专业名称" width="180" />

                            
                    </el-table>
                </div> -->
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="seeVisible = false">关闭</el-button>
                    </span>
                    </template>
            </el-dialog>
    </div>
</template>
<script>
import { userInfo } from "api/apis.js";
import { FullScreen } from '@element-plus/icons-vue'
export default {
    // props:['seeVisible'],
    components:{
        FullScreen
    },
    data() {
        return {
            activeName:'1',
            seeVisible:false,
            isFullscreen:false,
            tableData:[],
            tableData2:[],
            payment_id:'',
            loading:false,
            innovateLoading:false,
            track:''
        }
    },
    created() {
        
    },
    methods:{
        //打开时的回调
        open(rows) {
            this.seeVisible = true
            if (rows){
                this.payment_id = rows.payment_id
            }
        },
        //信息通信工程实践
        getUserNumberss() {
            let uid = this.$store.state.User.user_id
            let dataobj = new FormData();
            dataobj.append("user_id", uid);
            // dataobj.append("page", this.page);//当前页数
            // dataobj.append("limit", this.pageSize);//一页显示多少条
            dataobj.append("payment_id", this.payment_id);//支付id
            
            this.loading = true;
            this.tableData2 = []
            this.tableData = []
            this.team_ids = [];
            userInfo.selsetUser(dataobj).then(res => {
                this.nums= 0;
                this.loading = false;
                const { count, data } = res;
                this.total = count || 0;
                console.log(JSON.parse(data));
                let trackA= []
                let trackB= []
                let trackC= []
                console.log(JSON.parse(data));
                JSON.parse(data).forEach(t=> {
                    if(t.track === '创新') {
                        trackA.push(t)
                        this.track = '创新'
                    }else if(t.track === '学生') {
                        trackB.push(t)
                        this.track = '学生'
                    }else if(t.track === '教师') {
                        trackC.push(t)
                        this.track = '教师'
                    }
                })
                console.log(trackA,trackB);
                // let totalNums = [] //计算队员总数
                // this.team_nums = +Number(JSON.parse(data).length);
                // JSON.parse(data).forEach(m=> {
                //     totalNums.push(...m.players)
                // })
                
                // this.nums = totalNums.length;
                // let trackType = [...JSON.parse(data)][0].track
                if(trackA.length) {  
                    let leveListP = [];
                    let leveListT = [];
                    trackA.forEach(ele=> {
  
                    //队员信息
                    leveListP=ele.players.map(accItem=> {
                        return {
                            school:ele.school,
                            province:ele.province,
                            name:ele.name,
                            role:accItem.role,
                            name1:accItem.name,
                            id_num:accItem.id_num,
                            phone:accItem.phone,
                            mail:accItem.mail,
                            sex:accItem.sex,
                            grade:accItem.grade,
                            profession:accItem.profession
                        }
                    });
                    //教师信息
                    leveListT=ele.teachers.map(accItem=> {
                        return {
                            school:ele.school,
                            province:ele.province,
                            name:ele.name,
                            role:accItem.role,
                            name1:accItem.name,
                            id_num:accItem.id_num,
                            phone:accItem.phone,
                            mail:accItem.mail,
                            sex:accItem.sex,
                            grade:accItem.grade,
                            profession:accItem.profession
                        }
                    })

                    this.tableData2.push(...leveListP);
                    this.tableData2.push(...leveListT);
                    // this.innovateTotal = Number(this.tableData2.length) 
                    });
                } 
                if(trackB.length) {
                    trackB.forEach((ele, index) => {
                    let obj = {}
                    this.team_ids.push(ele.team_id) //保存团队id
                    obj.group = ele.group
                    obj.province = ele.province
                    obj.school = ele.school
                    //队员信息
                    ele.players.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '1'] = item[key]
                            }
                        } else if (index === 1) {
                            for (let key in item) {
                                obj[key + '2'] = item[key]
                            }
                        }
                    })
                    //教师信息
                    ele.teachers_adviser.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '3'] = item[key]
                            }
                        } else if (index === 1) {
                            for (let key in item) {
                                obj[key + '4'] = item[key]
                            }
                        } else if (index === 2) {
                            obj.tphone3 = item.phone
                            obj.tmail3 = item.mail
                        }

                    })
                    //带队教师信息
                    ele.teachers_leader.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '5'] = item[key]
                            }
                        } 
                    })
                    this.tableData.push(obj);
                })
                }
                if(trackC.length) {
                    trackC.forEach((ele, index) => {
                    let obj = {}
                    obj.group = ele.group
                    obj.province = ele.province
                    obj.school = ele.school
                    //队员信息
                    ele.players.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '1'] = item[key]
                            }
                        } else if (index === 1) {
                            for (let key in item) {
                                obj[key + '2'] = item[key]
                            }
                        }
                    })
                    //带队教师信息
                    ele.teachers_leader.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '5'] = item[key]
                            }
                        } 
                    })
                    
                    this.tableData.push(obj);
                    console.log(this.tableData)
                })
                }
            })
        },
        fullScreen() {
            this.$nextTick(()=> {
                this.isFullscreen = !this.isFullscreen
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    :deep(.el-dialog) {
        // height: 5rem;
        .el-dialog__headerbtn {
            top:-0.1rem;
        }
        .dialogBox {
            margin-bottom: 0.2rem;
        }
        .el-dialog__footer {

        }
        .el-button{
            width: 1.71rem !important;
            height: 0.45rem !important;
            font-size: 0.24rem !important;
            color: white !important;
            background-color: #436E8A !important; 
        }
    }
    :deep(.el-dialog__header) {
        padding: 0 !important;
    }
    :deep(.el-dialog__footer) {
        text-align: center;
    }
    
</style>