<template>
    <div class="container-box">
        <el-card class="box-card" style="height: 7rem;">
            <el-table v-loading="ReviewedLoading" border height='6.5rem' :scrollbar-always-on="true" :data="tableData3"
                :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }"
                style="width: 100%;border-radius: 0.05rem;">
                <el-table-column type="index" sortable label="序号" width="60" />
                <el-table-column prop="number" sortable label="本次报名人数" width="140" />
                <el-table-column prop="team_num" sortable label="本次报名组数" width="140" />
                <el-table-column prop="money" sortable label="本次上传应缴费" width="180" />
                <el-table-column prop="status" sortable label="审核信息" width="180">
                    <template #default="scope"> 
                        <span v-if="scope.row.status == '审核成功' || scope.row.status == '待审核'">{{ scope.row.status }}</span>
                        <span class="decorationBox" v-if="scope.row.status == '审核失败'"
                            @click="viewReason(scope.row)">{{ scope.row.status }}</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="invoice_content" sortable label="发票类型" width="180" />
                <el-table-column prop="invoice_title" sortable label="发票抬头" width="180" />
                <el-table-column prop="invoice_num" sortable label="税号" width="200" /> -->
                <el-table-column prop="memo" sortable label="发票备注栏信息" width="180" />
                <!-- <el-table-column prop="invoice_address" sortable label="地址" width="200" /> -->
                <el-table-column prop="create_time" sortable label="提交时间" width="200" />
                <el-table-column fixed="right" label="操作" width="200">
                    <template #default="scope">
                        <el-button type="primary" size="small" @click.prevent="view(scope.row)">
                            <!-- <el-icon style="vertical-align: middle">
                                <Search />
                            </el-icon> -->
                            查看图片
                        </el-button>
                        <el-button type="primary" size="small" @click.prevent="onlyReadDetail(scope.row)">
                            人员明细
                        </el-button>

                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog v-model="dialogVisible" width="20%" :before-close="handleClose">
            <div class="dialogBox">
                <img :src="path" style="display:block;width:3.14rem;height:3.83rem;margin: 0 auto;">
                <!-- <img src="../../assets/images/wchat3.png" style="display:block;width:3.14rem;height:3.83rem;margin: 0 auto;"> -->
            </div>
        </el-dialog>
        <seeModelPage ref="dialogModel" :seeVisible="seeVisible" />
        <failModelPage ref="failModel" :failVisible="failVisible" />
    </div>
</template>
<script>
import { userInfo } from "api/apis.js";
import seeModelPage from './seeModel.vue';
import failModelPage from './failModel.vue';
export default {
    components: {
        // Search,
        // upload,
        seeModelPage,
        failModelPage
    },
    data() {
        return {
            dialogVisible: false,
            ReviewedLoading: false,
            seeVisible: false,
            failVisible: false,
            tableData3: [], //已审核
            path: ''
        }
    },
    created() {
        this.getNotUserNumber();
    },
    methods: {
        //已审核信息
        getNotUserNumber() {
            let uid = this.$store.state.User.user_id
            let dataobj = new FormData();
            dataobj.append("user_id", uid);
            dataobj.append("page", '1');//当前页数
            dataobj.append("limit", 50);//一页显示多少条
            this.ReviewedLoading = true;
            userInfo.notSelsetUser(dataobj).then(res => {
                this.ReviewedLoading = false;
                const { count, data } = res;
                this.tableData3 = JSON.parse(data);
            })
        },
        //查看详情
        viewReason(rows) {
            this.$refs.failModel.open(rows);
        },
        view(row) {
            console.log(row);
            this.dialogVisible = true
            this.path = row.pay_pic_path
        },
        onlyReadDetail(rows) {
            this.$refs.dialogModel.open(rows);
            this.$refs.dialogModel.getUserNumberss();  
        },

    }
}
</script>

<style lang="scss" scoped>
.container-box {
    width: 95%;
    margin: auto;
}

:deep .el-table th.el-table__cell.is-sortable{
    text-align: center;
}
</style>